import React from 'react'

const Footer = () => (
    <div className="has-text-centered">
    <footer>
        <p>© RinRin Catcher - 2019. All rights reserved.</p>
    </footer>
  </div>
)

export default Footer