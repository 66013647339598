import React from 'react';
import { NavLink } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
//import Cookies from 'universal-cookie';
import Cookies from 'js-cookie';

//const cookies = new Cookies();

class Header extends React.Component {

  state = {
    isActive: false,
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }))
  }

  render() {
    return (
<nav className="navbar is-light" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <a className="navbar-item" href="/">
                <img
                  src={process.env.PUBLIC_URL + "/index_files/image004.png"}
                  alt="RinRin Catcher"
                  width="28"
                  height="28"
                />{" "}
                <div className="navbar-item">RinRin Catcher</div>
              </a>
              <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={this.toggleNav}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
            </div>
            <div className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
            <div className="navbar-start">
              <NavLink exact to="/" activeClassName="is-active" className="navbar-item">Home</NavLink>
              <NavLink to="/view" activeClassName="is-active" className="navbar-item">View</NavLink>
              <NavLink to="/play" activeClassName="is-active" className="navbar-item">Play</NavLink>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">More</a>
                <div className="navbar-dropdown">
                  <NavLink to="/about" activeClassName="is-active" className="navbar-item">About</NavLink>
                  <a className="navbar-item">Jobs</a>
                  <a className="navbar-item">Contact</a>
                  <hr className="navbar-divider" />
                  <a className="navbar-item">Report an issue</a>
                </div>
              </div>
            </div>



              <div className="navbar-end">
                <a className="navbar-item" href="" target="_blank">
                Welcome, {firebase.auth().currentUser.displayName}!   {"  "}
                <div className="navbar-item">
                <img src={firebase.auth().currentUser.photoURL} alt="RinRin Catcher" width="28" height="28" />
                </div>
                </a>
                <div className="navbar-item buttons">
                  <div className="buttons">
                    <button className="button is-primary" onClick={() => {
                      firebase.auth().signOut();
                      //reset cookie
                      Cookies.set('rrId', "");
                      Cookies.set('sessionId', "");
                      console.log("Cookie rrID",Cookies.get('rrId'));
                      console.log("Cookie sessionID",Cookies.get('sessionId'));
                      }
                    }>Sign Out</button>
                  </div>
                </div>
              </div>
            </div>
            <div />
          </div>
        </nav>
    )
  }
}

export default Header