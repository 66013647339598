import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import Footer from './app/Layout/Footer';
import { CookiesProvider } from 'react-cookie';

const AppWithRouter = () => (
  <CookiesProvider>
  <BrowserRouter>
    <App />
    <Footer />
  </BrowserRouter>
  </CookiesProvider>
)

ReactDOM.render(<AppWithRouter />, document.getElementById('root'))
registerServiceWorker()
