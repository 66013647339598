import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from '../pages/Home'
import About from '../pages/About'
import View from '../pages/View'
import Play from '../pages/Play'
import Profile from '../pages/Profile'


export default () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/about" component={About} />
    <Route exact path="/view" component={View} />
    <Route exact path="/play" component={Play} />
    <Route exact path="/profile" component={Profile} />
  </Switch>
)