import React from 'react';

export default () => (
  <div className="has-text-centered">
    <section className="hero is-info">
      <div className="container">
        <h1 className="title">Profile Page</h1>
      </div>
    </section>
  </div>
)
