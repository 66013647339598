// React core
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Routing from "./routes";
import Header from './app/Layout/Header';

// Firebase.
import firebase from "firebase/app";
import "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

// Styles
import "./App.css"; // This uses CSS modules.

import axios from "axios";
import Snow from "react-snow-effect";

//import Cookies from 'universal-cookie';
//import { withCookies } from 'react-cookie';
import Cookies from 'js-cookie';


import mp3_file from "./index_files/Intro_01.mp3";

//const cookies = new Cookies();
//const check_cookie = Cookies.get('sessionID');

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyD0-i-Wup-oG8RpzpJEc_gAZf-P7bVqDnM",
  authDomain: "rinrin-login.firebaseapp.com",
  databaseURL: "https://rinrin-login.firebaseio.com",
  projectId: "rinrin-login",
  storageBucket: "rinrin-login.appspot.com",
  messagingSenderId: "570886255015"
};

// Instantiate a Firebase app.
firebase.initializeApp(firebaseConfig);

class SignInScreen extends Component {
  // The component's Local state.
  state = {
    isSignedIn: false, // Local signed-in state.
    rrID: Cookies.get('rrId'),
    sessionID: Cookies.get('sessionID'),
    creditAmount: Cookies.get('creditAmount'),
    pointAmount: Cookies.get('pointAmount')
  };

  // Configure FirebaseUI.
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google , Facebook , Etc as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      //signInSuccess: () => false
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        console.log("signInSuccessWithAuthResult", authResult, redirectUrl);
        //--axios
        var firstname = "";
        if (authResult.additionalUserInfo.providerId === "facebook.com") {
          firstname = authResult.additionalUserInfo.profile.first_name;
        } else {
          firstname = authResult.additionalUserInfo.profile.given_name;
        }
        var geturl =
          "http://localhost:8080/WebserviceTest/api/v1/user/userInfo/" +
          authResult.additionalUserInfo.profile.email +
          "/" +
          authResult.additionalUserInfo.profile.id +
          "/" +
          authResult.additionalUserInfo.providerId +
          "/" +
          firstname;
        console.log("geturl", geturl);
        axios.get(geturl).then(response => {
          console.log("DBresponse", response);
          //set cookie
          //Cookies.set('rrId', response.data.rrId, { path: '/' });
          //Cookies.set('sessionId', response.data.sessionId, { path: '/' });
          Cookies.set('rrId', response.data.rrId, { expires: 7 });
          Cookies.set('sessionId', response.data.sessionId, { expires: 7 });
          Cookies.set('creditAmount', response.data.creditAmount, { expires: 7 });
          Cookies.set('pointAmount', response.data.pointAmount, { expires: 7 });
        console.log("Cookie rrID ",Cookies.get('rrId'));
        console.log("Cookie SessionID ",Cookies.get('sessionId'));
        console.log("Cookie creditAmount ",Cookies.get('creditAmount'));
        console.log("Cookie pointAmount ",Cookies.get('pointAmount'));
        }
        );
        
        //
        this.props.history.push("/");
        return false;
        //
      }
    }
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(user => this.setState({ isSignedIn: !!user }));
      console.log("Set State ", this.state.isSignedIn);
      console.log("Cookie rrID ",Cookies.get('rrId'));
      console.log("Cookie SessionID ",Cookies.get('sessionId'));
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if (!this.state.isSignedIn) {
      return (
        <div className="rinmainbg">
        <Snow />
        <audio src={mp3_file} loop autoPlay/>
        <div className="container">
          <br />
          <br />
          <center>
            <h1>
              <b>RinRin Catcher</b>
            </h1>
          </center>
          <br />
          <center>
            <img width="329" height="321" src="index_files/image004.png" />
            <br />
          </center>

          <center>
            <p>Please sign-in:</p>
          </center>
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
        <footer>
          <center>© RinRin Catcher - 2019. All rights reserved.</center>
          <br>
          </br>
          <button className="button button-large is-primary" value={Cookies.get('sessionId')}></button>
        </footer>
        </div>
      );
    }
    else {
    return (
      //start routing
      <div className="my-app">
        <Snow />
        <audio src={mp3_file} loop autoPlay/>
        <Header />
        <Routing />
      </div>

      //end routing
    );
    }
  }
}

export default SignInScreen;
