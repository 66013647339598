import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Redirect } from "react-router";
import Cookies from 'js-cookie';
//import { NavLink } from "react-router-dom";
//import Cookies from 'universal-cookie';

export default class FooBar extends React.Component {
  state = {
    navigate: false,
    id: 0
  }

  render() {
    const { navigate, id } = this.state;
    //const cookies = new Cookies();

    // here is the important part
    if (navigate) {
      if(id === 1){
        return <Redirect to="/play" push={true} />

      } else if(id === 2){
        return <Redirect to="/profile" push={true} />

      } else if(id === 3){
        return <Redirect to="/about" push={true} />

      }
      
    }
   // ^^^^^^^^^^^^^^^^^^^^^^^

    return (
      <div className="has-text-centered">
<section className="hero is-info">
  <div className="container">
  <nav>
      <div>
      <p className="level-item subtitle is-5">
      <img id="point" className="pic" src="index_files/point_icon.png" width="34" height="34" />&nbsp;&nbsp;<strong>Point  {Cookies.get('pointAmount')}</strong>&nbsp;&nbsp;
      <img id="credit" className="pic" src="index_files/credit_icon.png" width="34" height="34" />&nbsp;&nbsp;<strong>Credit  {Cookies.get('creditAmount')}</strong>&nbsp;&nbsp;
      <img id="credit" className="pic" src="index_files/freeplay_icon.png" width="34" height="34" />&nbsp;&nbsp;<strong>Free  {Cookies.get('rrId')}</strong>&nbsp;&nbsp;
      </p>
      
    </div>
      </nav>
    <br /><br />
    <div>
      <img id="photo1" className="pic" src="index_files/but_01_gameplay.png" width="300" onClick={() => this.setState({ navigate: true, id: 1 })}/>
      <br /><br />
      <img id="photo2" className="pic" src="index_files/but_01_reward.png" width="300" onClick={() => this.setState({ navigate: true, id: 2 })}/>
      <br /><br />
      <img id="photo2" className="pic" src="index_files/but_01_ticket.png" width="300" onClick={() => this.setState({ navigate: true, id: 3 })}/>
      <br></br>
      <button className="button button-large is-primary" onClick={() => {
        firebase.auth().signOut();
        //reset cookie
        Cookies.set('rrId', "");
        Cookies.set('sessionId', "");
        console.log("Cookie rrID",Cookies.get('rrId'));
        console.log("Cookie sessionID",Cookies.get('sessionId'));
      }
      }>Sign-out</button>
      <br>
      </br>
      <button className="button button-large is-primary">{Cookies.get('rrId')}</button>
    </div>
  </div>
</section>
</div>
    )
  }
}
