import React from "react";

export default () => (
  <div className="has-text-centered">
    <section className="hero is-info">
      <div className="container">
        <h1 className="title">About</h1>
        <p>About RinRin Catcher</p>
        <p>Catch me if you can. Let's have FUN !!!</p>
      </div>
    </section>
  </div>
);
